import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import printJS from 'print-js';
import moment from 'moment';
import classnames from 'classnames';
import Top from 'Components/top';
import LoadingOverlay from 'Components/loading-overlay';
import loc from 'Components/languages';
import Button from 'Components/button';
import { viewOrder, printOrder } from 'Redux/actions';
import styles from './PageOrderView.less';

class PageOrderView extends PureComponent {
	constructor() {
		super();
		this.getId = this.getId.bind(this);
		this.viewOrder = this.viewOrder.bind(this);
		this.isLoading = this.isLoading.bind(this);
		this.onClickPrintHandler = this.onClickPrintHandler.bind(this);
		this.onClickDownloadHandler = this.onClickDownloadHandler.bind(this);
	}

	componentDidMount() {
		this.viewOrder();
	}

	getId(props = null) {
		if (!props) {
			props = this.props;
		}
		return props.match.params.transactionNumber;
	}

	viewOrder() {
		const param = {
			payload: {
				trx_no: this.getId(),
			},
		};
		this.props.viewOrder(param);
	}

	isLoading() {
		const { viewOrderInfo } = this.props;
		return viewOrderInfo.isFetching;
	}

	onClickPrintHandler() {
		const { viewOrderInfo } = this.props;
		printJS({
			printable: viewOrderInfo.data.pdf,
			type: 'pdf',
			base64: true
		});

		const param = {
			payload: {
				trx_no: this.getId(),
			},
		};
		this.props.printOrder(param);
	}

	onClickDownloadHandler() {
		const { viewOrderInfo } = this.props;
		if (viewOrderInfo && viewOrderInfo.data && viewOrderInfo.data.pdf) {
			const linkSource = `data:application/pdf;base64,${viewOrderInfo.data.pdf}`;
			const downloadLink = document.createElement('a');
			const fileName = `${this.getId()}.pdf`;

			downloadLink.href = linkSource;
			downloadLink.download = fileName;
			downloadLink.click();
		}
	}

	render() {
		const { viewOrderInfo } = this.props;
		const isLoading = this.isLoading();
		const transactionNumber = this.getId();
		const transactionDate = (viewOrderInfo && viewOrderInfo.data && viewOrderInfo.data.transaction_date) ? moment(viewOrderInfo.data.transaction_date) : null;
		return (
			<LoadingOverlay active={ isLoading }>
				<div className="uk-flex uk-flex-column">
					<Top name="orderSearch" />
					<div>
						<div
							className="stepContainer uk-height-large uk-position-relative"
							data-uk-height-viewport="offset-top: true; offset-bottom: 64px"
						>
							<div className="uk-width-1-1 uk-height-1-1">
								<h3 className={ classnames('uk-text-primary', 'uk-text-bold', 'uk-text-uppercase', styles.title) }>
									{ loc.print }
									<small className="uk-margin-left">
										{
											transactionDate && (
												`Ordered on ${transactionDate.format('YYYY-MM-DD HH:mm:ss')} / `
											)
										}
										Order# { transactionNumber }
									</small>
								</h3>
								{
									viewOrderInfo && viewOrderInfo.data && viewOrderInfo.data.pdf ? (
										<iframe
											src={ `data:application/pdf;base64,${viewOrderInfo.data.pdf}${viewOrderInfo.data.orientation === 'landscape' ? '#zoom=200' : ''}` }
											className={ styles.pdf }
										/>
									) : (
										<div />
									)
								}
								{
									viewOrderInfo && viewOrderInfo.data && (
										<div className="uk-position-top-right uk-padding-small uk-padding-remove-vertical uk-padding-remove-right">
											{
												viewOrderInfo.data.pdf && (
													<Button
														text={ loc.download }
														theme="primary"
														className="uk-margin-right"
														disabled={ isLoading }
														onClick={ this.onClickDownloadHandler }
													/>
												)
											}
											{
												viewOrderInfo.data.allow_printing && (
													<Button
														text={ loc.printInvoice }
														theme="primary"
														disabled={ isLoading }
														onClick={ this.onClickPrintHandler }
													/>
												)
											}
										</div>
									)
								}
							</div>
						</div>
					</div>
				</div>
			</LoadingOverlay>
		);
	}
}

export default connect(
	(state) => ({
		viewOrderInfo: state.viewOrderInfo,
	}),
	(dispatch) => ({
		viewOrder: para => dispatch(viewOrder(para)),
		printOrder: para => dispatch(printOrder(para)),
	})
)(PageOrderView);