import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import classnames from 'classnames';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { convertFloatToPrice } from 'Helpers/helpers';
import { setDefaultSalesOrderData, setDefaultServiceMemoData } from 'Redux/actions_ui';
import { postApproval, convertQtoR, convertQtoRServiceMemo, depositRefund, depositRefundServiceMemo, retrieveHoldOrder, retrieveHoldServiceMemo, voidOrder } from 'Redux/actions';
import * as cons from 'Redux/constants';
import loc from 'Components/languages';
import DropdownMenu from 'Components/dropdown-menu';
import ModalApproval from './ModalApproval.jsx';
import ModalReturn from './ModalReturn.jsx';
import styles from './TransactionListItem.less';

class TransactionListItem extends PureComponent {
	constructor() {
		super();
		this.state = {
			showDropdownMenu: false,
			showModalApproval: false,
			showModalReturn: false,
			approvalType: null,
			approvalUserId: null,
		};
		this.setIsVerified = this.setIsVerified.bind(this);
		this.onClickHandler = this.onClickHandler.bind(this);
		this.onToggleDropdownMenuHandler = this.onToggleDropdownMenuHandler.bind(this);
		this.onClickRedeemOrderHandler = this.onClickRedeemOrderHandler.bind(this);
		this.onClickDepositRefundHandler = this.onClickDepositRefundHandler.bind(this);
		this.onClickVoidOrderHandler = this.onClickVoidOrderHandler.bind(this);
		this.onClickReturnItemHandler = this.onClickReturnItemHandler.bind(this);
		this.onClickRetrieveHoldOrder = this.onClickRetrieveHoldOrder.bind(this);
		this.onToggleModalApprovalHandler = this.onToggleModalApprovalHandler.bind(this);
		this.onToggleModalReturnHandler = this.onToggleModalReturnHandler.bind(this);
		this.onRequestApprovalHandler = this.onRequestApprovalHandler.bind(this);
		this.onRequestReturnHandler = this.onRequestReturnHandler.bind(this);
	}

	componentDidUpdate(prevProps) {
		const { approvalType, approvalUserId } = this.state;
		if (approvalType && approvalUserId) {
			const approvalInfo = this.props.approvalInfo;
			const prevApprovalInfo = prevProps.approvalInfo;
			const voidOrderInfo = this.props.voidOrderInfo;
			const prevVoidOrderInfo = prevProps.voidOrderInfo;
			if (approvalInfo && approvalInfo !== prevApprovalInfo) {
				switch (approvalInfo.type) {
				case cons.POST_APPROVAL.SUCCESS:
					this.setIsVerified(approvalInfo.data.is_verfied);
					break;
				case cons.POST_APPROVAL.FAILURE:
					this.setIsVerified(false);
					break;
				}
			}

			if (voidOrderInfo && voidOrderInfo !== prevVoidOrderInfo) {
				let resetState = true;
				switch (voidOrderInfo.type) {
				case cons.VOID_ORDER.SUCCESS:
					UIkit.notification('The order is voided successfully.', { status: 'success' });
					this.props.history.push(`/app/order/view/${voidOrderInfo.data.transaction_number}`);
					break;
				case cons.VOID_ORDER.FAILURE:
					UIkit.notification('The order is NOT voided.', { status: 'danger' });
					break;
				default:
					resetState = false;
					break;
				}

				if (resetState) {
					this.setState({
						showModalApproval: false,
						approvalType: null,
						approvalUserId: null,
					});
				}
			}
		}

		const salesOrderInfo = this.props.salesOrderInfo;
		const prevSalesOrderInfo = prevProps.salesOrderInfo;
		if (salesOrderInfo && salesOrderInfo !== prevSalesOrderInfo) {
			switch (salesOrderInfo.type) {
			case cons.CONVERT_Q_To_R.SUCCESS:
				this.props.history.push('/app/sales-order/salesman?action=q_to_r');
				break;
			case cons.DEPOSIT_REFUND.SUCCESS:
				this.props.history.push('/app/sales-order/salesman?action=deposit_refund');
				break;
			}
		}

		const serviceMemoInfo = this.props.serviceMemoInfo;
		const prevServiceMemoInfo = prevProps.serviceMemoInfo;
		if (serviceMemoInfo && serviceMemoInfo !== prevServiceMemoInfo) {
			switch (serviceMemoInfo.type) {
			case cons.CONVERT_Q_To_R_SERVICEMEMO.SUCCESS:
				this.props.history.push('/app/service-memo/salesman?action=q_to_r');
				break;
			case cons.DEPOSIT_REFUND_SERVICEMEMO.SUCCESS:
				this.props.history.push('/app/service-memo/salesman?action=deposit_refund');
				break;
			}
		}

		const retrieveHoldSalesOrderInfo = this.props.retrieveHoldSalesOrderInfo;
		const prevRetrieveHoldSalesOrderInfo = prevProps.retrieveHoldSalesOrderInfo;
		if (retrieveHoldSalesOrderInfo && retrieveHoldSalesOrderInfo !== prevRetrieveHoldSalesOrderInfo) {
			if (retrieveHoldSalesOrderInfo.type === cons.RETRIEVE_HOLD_SALESORDER.SUCCESS) {
				this.props.history.push('/app/sales-order/cart?action=hold');
			}
		}

		const retrieveHoldServiceMemoInfo = this.props.retrieveHoldServiceMemoInfo;
		const prevRetrieveHoldServiceMemoInfo = prevProps.retrieveHoldServiceMemoInfo;
		if (retrieveHoldServiceMemoInfo && retrieveHoldServiceMemoInfo !== prevRetrieveHoldServiceMemoInfo) {
			if (retrieveHoldServiceMemoInfo.type === cons.RETRIEVE_HOLD_SERVICEMEMO.SUCCESS) {
				this.props.history.push('/app/service-memo/cart?action=hold');
			}
		}
	}

	setIsVerified(isVerified) {
		if (!isVerified) {
			return;
		}

		const { transaction } = this.props;
		const { approvalType, approvalUserId } = this.state;
		let param = {
			payload: {}
		};

		switch (approvalType) {
		case 'voidOrder':
			param.payload = {
				transaction_number: transaction.transaction_number,
				is_approval_needed: true,
				is_approval_verified: isVerified,
				approval_user_code: approvalUserId,
			};
			this.props.voidOrder(param);

			this.setState({
				showModalApproval: false,
			});
			break;
		case 'returnItem':
			this.setState({
				approvalType: null,
				showModalApproval: false,
				showModalReturn: true,
			});
			break;
		}
	}

	onClickHandler() {
		const { transaction, onSelect } = this.props;
		onSelect(transaction);
	}

	onToggleDropdownMenuHandler() {
		const { showDropdownMenu } = this.state;
		const { onShowOverlay, onHideOverlay } = this.props;
		this.setState({
			showDropdownMenu: !showDropdownMenu,
		});
		if (showDropdownMenu) {
			onHideOverlay();
		} else {
			onShowOverlay(this.onToggleDropdownMenuHandler);
		}
	}

	onClickRedeemOrderHandler(e) {
		e.preventDefault();
		const { type, transaction, onHideOverlay, convertQtoR, convertQtoRServiceMemo } = this.props;
		const param = {
			payload: {
				transaction_number: transaction.transaction_number,
			},
		};
		onHideOverlay();
		if (type === 'salesQ') {
			convertQtoR(param);
		} else if (type === 'serviceQ') {
			convertQtoRServiceMemo(param);
		}
	}

	onClickDepositRefundHandler(e) {
		e.preventDefault();
		const { type, transaction, onHideOverlay, depositRefund, depositRefundServiceMemo } = this.props;
		const param = {
			payload: {
				transaction_number: transaction.transaction_number,
			},
		};
		onHideOverlay();
		if (type === 'salesQ') {
			depositRefund(param);
		} else if (type === 'serviceQ') {
			depositRefundServiceMemo(param);
		}
	}

	onClickVoidOrderHandler(e) {
		e.preventDefault();
		this.setState({
			showModalApproval: true,
			approvalType: 'voidOrder',
		}, this.props.onHideOverlay);
	}

	onClickReturnItemHandler(e) {
		e.preventDefault();
		this.setState({
			showModalReturn: true,
			approvalType: 'returnItem',
		}, this.props.onHideOverlay);
	}

	onClickRetrieveHoldOrder(e) {
		e.preventDefault();
		const { type, transaction, onHideOverlay, retrieveHoldOrder, retrieveHoldServiceMemo } = this.props;
		const param = {
			payload: {
				transaction_number: transaction.transaction_number,
			},
		};
		onHideOverlay();
		if (type === 'onHoldSalesOrder') {
			retrieveHoldOrder(param);
		} else if (type === 'onHoldServiceMemo') {
			retrieveHoldServiceMemo(param);
		}
	}

	onToggleModalApprovalHandler() {
		this.setState({
			showModalApproval: false,
			approvalType: null,
		});
	}

	onToggleModalReturnHandler() {
		const { showModalReturn } = this.state;
		this.setState({
			showModalReturn: !showModalReturn,
		});
	}

	onRequestApprovalHandler({ user_id, code }) {
		const param = {
			payload: {
				user_id,
				code,
			},
		};
		this.setState({
			approvalUserId: user_id,
		});
		this.props.postApproval(param);
	}

	onRequestReturnHandler(transactionItems) {
		const { type, transaction } = this.props;
		const data = {
			salesman_code1: transaction.salesman_code1,
			salesman_name1: transaction.salesman_name1,
			salesman_code2: transaction.salesman_code2,
			salesman_name2: transaction.salesman_name2,
			optometrist_code: transaction.optometrist_code,
			optometrist_name: transaction.optometrist_name,
			member_code: transaction.member_code,
			member: transaction.member,
			return_items: transactionItems,
			return_transaction_number: transaction.transaction_number,
			customer_anly_code: transaction.customer_anly_code,
			customer_anly_description: transaction.customer_anly_description,
		};
		if (type.indexOf('service') !== -1) {
			this.props.setDefaultServiceMemoData({ data });
			this.props.history.push('/app/service-memo/salesman');
		} else {
			this.props.setDefaultSalesOrderData({ data });
			this.props.history.push('/app/sales-order/salesman');
		}
	}

	render() {
		const { type, transaction, appLanguage } = this.props;
		const { showDropdownMenu, showModalApproval, showModalReturn } = this.state;
		const transactionDate = transaction.transaction_date ? moment(transaction.transaction_date) : null;
		let memberInfo = [];
		transaction.member.member_code && memberInfo.push(transaction.member.member_code);
		transaction.member.member_name && memberInfo.push(transaction.member.member_name);
		transaction.member.phone && memberInfo.push(transaction.member.phone);
		transaction.customer_anly_description && memberInfo.push(transaction.customer_anly_description);
		let transactionStatus = transaction.transaction_status_name_en;
		switch (appLanguage.language) {
		case 'zh-Hant':
			transactionStatus = transaction.transaction_status_name_tc;
			break;
		case 'zh-Hans':
			transactionStatus = transaction.transaction_status_name_sc;
			break;
		}
		return (
			<Fragment>
				<li
					onClick={ this.onClickHandler }
					className={ styles.list }
				>
					<div className="uk-card uk-card-body uk-card-small uk-position-relative">
						<div className="uk-flex uk-flex-between uk-child-width-1-4 uk-h6 uk-text-uppercase uk-margin-remove-bottom">
							<div className="uk-text-secondary">
								{ transaction.transaction_number }
							</div>
							<div className="uk-text-secondary">
								{ transactionDate ? transactionDate.format('YYYY-MM-DD HH:mm:ss') : '' }
							</div>
							<div>&nbsp;</div>
							{
								type.indexOf('onHold') === -1 ? (
									<div className="uk-text-capitalize">
										{ transactionStatus }
									</div>
								) : (
									<div className={ classnames('uk-text-capitalize', 'uk-text-small', 'uk-text-muted', styles.amount) }>
										{ loc.amount }: { convertFloatToPrice(transaction.transaction_amount) }
									</div>
								)
							}
						</div>
						<div className="uk-flex uk-flex-between uk-text-small uk-text-muted">
							<div className="uk-width-3-4">
								{ transaction.member && memberInfo.join(' / ') }
							</div>
							{
								type.indexOf('onHold') === -1 && (
									<div className="uk-width-1-4 uk-text-capitalize">
										{ loc.amount }: { convertFloatToPrice(transaction.transaction_amount) }
									</div>
								)
							}
						</div>
						<div className="uk-flex uk-flex-between uk-text-small uk-text-muted">
							<div className={ classnames('uk-width-3-4', styles.remark) }>{ transaction.remark }</div>
							<div className="uk-width-1-4">{ loc.qty }: { transaction.transaction_quantity }</div>
						</div>
						<div className={ classnames('uk-position-top-right', styles.menu) }>
							<div className={ styles.button } onClick={ this.onToggleDropdownMenuHandler }>
								<FontAwesomeIcon icon={ faEllipsisV } className="fa-lg" />
							</div>
							<DropdownMenu
								width="200px"
								active={ showDropdownMenu }
								onToggle={ this.onToggleDropdownMenuHandler }
								className={ styles.dropdownMenu }
							>
								{
									(type === 'onHoldSalesOrder' || type === 'onHoldServiceMemo') ? (
										<li><a href="#" className="uk-text-danger" onClick={ this.onClickRetrieveHoldOrder }>{ loc.retrieveHoldOrder }</a></li>
									) : (
										<Fragment>
											<li>
												<Link to={ `/app/order/view/${transaction.transaction_number}` }>
													{ loc.viewOrder }
												</Link>
											</li>
											{
												transaction.allowQToR && (
													<Fragment>
														<li><a href="#" onClick={ this.onClickRedeemOrderHandler }>{ loc.redeemOrder }</a></li>
														<li><a href="#" onClick={ this.onClickDepositRefundHandler }>{ loc.depositRefund }</a></li>
													</Fragment>
												)
											}
											{
												transaction.allowVoid && transactionDate.isSame(new Date(), 'day') && (
													<li><a href="#" className="uk-text-danger" onClick={ this.onClickVoidOrderHandler }>{ loc.voidOrder }</a></li>
												)
											}
											{
												transaction.allowReturn && (
													<li><a href="#" className="uk-text-danger" onClick={ this.onClickReturnItemHandler }>{ loc.return }</a></li>
												)
											}
										</Fragment>
									)
								}
							</DropdownMenu>
						</div>
					</div>
				</li>

				<ModalApproval
					isOpen={ showModalApproval }
					onToggle={ this.onToggleModalApprovalHandler }
					onSubmit={ this.onRequestApprovalHandler }
				/>

				{
					transaction.allowReturn && (
						<ModalReturn
							isOpen={ showModalReturn }
							onToggle={ this.onToggleModalReturnHandler }
							onReturn={ this.onRequestReturnHandler }
							transactionNumber={ transaction.transaction_number }
						/>
					)
				}
			</Fragment>
		);
	}
}

TransactionListItem.propTypes = {
	index: PropTypes.number.isRequired,
	type: PropTypes.string.isRequired,
	transaction: PropTypes.object.isRequired,
	onShowOverlay: PropTypes.func.isRequired,
	onHideOverlay: PropTypes.func.isRequired,
	onSelect: PropTypes.func.isRequired,
};

export default withRouter(connect(
	(state) => ({
		appLanguage: state.appLanguage,
		salesOrderInfo: state.salesOrderInfo,
		serviceMemoInfo: state.serviceMemoInfo,
		approvalInfo: state.approvalInfo,
		voidOrderInfo: state.voidOrderInfo,
		retrieveHoldSalesOrderInfo: state.retrieveHoldSalesOrderInfo,
		retrieveHoldServiceMemoInfo: state.retrieveHoldServiceMemoInfo,
	}),
	(dispatch) => ({
		setDefaultSalesOrderData: (para) => dispatch(setDefaultSalesOrderData(para)),
		setDefaultServiceMemoData: (para) => dispatch(setDefaultServiceMemoData(para)),
		postApproval: (para) => dispatch(postApproval(para)),
		convertQtoR: (para) => dispatch(convertQtoR(para)),
		convertQtoRServiceMemo: para => dispatch(convertQtoRServiceMemo(para)),
		depositRefund: (para) => dispatch(depositRefund(para)),
		depositRefundServiceMemo: para => dispatch(depositRefundServiceMemo(para)),
		retrieveHoldOrder: (para) => dispatch(retrieveHoldOrder(para)),
		retrieveHoldServiceMemo: para => dispatch(retrieveHoldServiceMemo(para)),
		voidOrder: (para) => dispatch(voidOrder(para)),
	})
)(TransactionListItem));
