import React, { PureComponent, Fragment } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSlidersH } from '@fortawesome/free-solid-svg-icons';
import { convertDateForApi, setDefaultDateFrom } from 'Helpers/helpers';
import { getTransactions, getTransferInOrder, getTransferOutOrder } from 'Redux/actions';
import * as cons from 'Redux/constants';
import Top from 'Components/top';
import Tab from 'Components/tab';
import Pagination from 'Components/pagination';
import LoadingOverlay from 'Components/loading-overlay';
import loc from 'Components/languages';
import Overlay from 'Components/overlay';
import TransactionListItem from './components/TransactionListItem.jsx';
import TransferOrderListItem from './components/TransferOrderListItem.jsx';
import ModalAdvancedSearch from './components/ModalAdvancedSearch.jsx';
import styles from './PageOrderSearch.less';

class PageOrderSearch extends PureComponent {
	constructor() {
		super();
		this.defaultParam = new Map({
			size: 20,
			sort_direction: 'desc',
			sort_column: 'date',
			is_current_shop: true,
			transaction_start_date: convertDateForApi(setDefaultDateFrom(), '00:00:00'),
			transaction_end_date: convertDateForApi(new Date(), '23:59:59'),
			transaction_status: 'all',
		});
		this.state = {
			currentTab: 'salesQ',
			param: this.defaultParam,
			page: 1,
			keyword: '',
			showOverlay: false,
			onHideOverlay: null,
			showModalAdvancedSearch: false,
		};
		this.tabs = [
			'salesQ',
			'salesR',
			'onHoldSalesOrder',
			'serviceQ',
			'serviceR',
			'onHoldServiceMemo',
			'transferIn',
			'transferOut',
			// 'shopOrder',
		];
		this.modalStyle = {
			content: {
				left: 'calc(50% - 250px)',
				right: 'calc(50% - 250px)',
				maxHeight: '600px',
				padding: 0,
			},
		};
		this.fetchData = this.fetchData.bind(this);
		this.isLoading = this.isLoading.bind(this);
		this.onChangeTabHandler = this.onChangeTabHandler.bind(this);
		this.onChangeKeywordHandler = this.onChangeKeywordHandler.bind(this);
		this.onChangePageHandler = this.onChangePageHandler.bind(this);
		this.onChangeParamHandler = this.onChangeParamHandler.bind(this);
		this.onSelectHandler = this.onSelectHandler.bind(this);
		this.onSubmitHandler = this.onSubmitHandler.bind(this);
		this.onShowOverlayHandler = this.onShowOverlayHandler.bind(this);
		this.onHideOverlayHandler = this.onHideOverlayHandler.bind(this);
		this.onToggleModalAdvancedSearchHandler = this.onToggleModalAdvancedSearchHandler.bind(this);
	}

	componentDidMount() {
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		const voidOrderInfo = this.props.voidOrderInfo;
		const prevVoidOrderInfo = prevProps.voidOrderInfo;
		if (voidOrderInfo && voidOrderInfo !== prevVoidOrderInfo) {
			if (voidOrderInfo.type === cons.VOID_ORDER.SUCCESS) {
				this.fetchData();
			}
		}
	}

	fetchData() {
		const { page, keyword, currentTab } = this.state;
		let param = {
			payload: this.state.param.toJS(),
		};
		param.payload.page = page;
		if (keyword) {
			param.payload.keyword = keyword;
		}
		switch (currentTab) {
		case 'salesQ':
			param.payload.doc_type = 'DP1';
			param.payload.is_on_hold = false;
			param.payload.is_service_memo = false;
			break;
		case 'salesR':
			param.payload.doc_type = 'SA1';
			param.payload.is_on_hold = false;
			param.payload.is_service_memo = false;
			break;
		case 'onHoldSalesOrder':
			param.payload.doc_type = 'HM1';
			param.payload.is_on_hold = true;
			param.payload.is_service_memo = false;
			break;
		case 'serviceQ':
			param.payload.doc_type = 'DP1';
			param.payload.is_on_hold = false;
			param.payload.is_service_memo = true;
			break;
		case 'serviceR':
			param.payload.doc_type = 'SA3';
			param.payload.is_on_hold = false;
			param.payload.is_service_memo = true;
			break;
		case 'onHoldServiceMemo':
			param.payload.doc_type = 'HM1';
			param.payload.is_on_hold = true;
			param.payload.is_service_memo = true;
			break;
		case 'transferIn':
			param.payload.doc_type = 'TI';
			break;
		case 'transferOut':
			param.payload.doc_type = 'TO';
			break;
		case 'shopOrder':
			param.payload.doc_type = 'OR1';
			param.payload.is_on_hold = false;
			param.payload.is_service_memo = false;
			break;
		}

		switch (currentTab) {
		case 'transferIn':
		case 'transferOut':
			param.payload = {
				page,
				keyword,
				size: param.payload.size,
				sort_column: 'st_date',
				sort_direction: param.payload.sort_direction,
				status: param.payload.transaction_status,
			};
			currentTab === 'transferIn' ? this.props.getTransferInOrder(param) : this.props.getTransferOutOrder(param);
			break;
		default:
			if (param.payload.doc_type === 'HM1') {
				param.payload.transaction_status = 'all';
			}
			switch (param.payload.doc_type) {
			case 'DP1':
			case 'SA1':
			case 'SA3':
			case 'OR1':
				break;
			default:
				delete param.payload.doc_type;
				break;
			}
			this.props.getTransactions(param);
			break;
		}
	}

	isLoading() {
		const { transactionsInfo, transferInOrderInfo, transferOutOrderInfo, salesOrderInfo } = this.props;
		return transactionsInfo.isFetching || transferInOrderInfo.isFetching || transferOutOrderInfo.isFetching || salesOrderInfo.isFetching;
	}

	onChangeTabHandler(tab) {
		this.setState({
			currentTab: tab,
			page: 1,
		}, this.fetchData);
	}

	onChangeKeywordHandler(e) {
		this.setState({
			page: 1,
			keyword: e.target.value,
		});
	}

	onChangePageHandler(data) {
		this.setState({
			page: data.selected + 1,
		}, this.fetchData);
	}

	onChangeParamHandler(data) {
		const { param } = this.state;
		this.setState({
			param: param.merge(data),
			showModalAdvancedSearch: false,
		}, this.fetchData);
	}

	onSelectHandler(/* transaction */) {

	}

	onSubmitHandler(e) {
		e.preventDefault();
		this.fetchData();
	}

	onShowOverlayHandler(callback) {
		this.setState({
			showOverlay: true,
			onHideOverlay: callback
		});
	}

	onHideOverlayHandler() {
		const { onHideOverlay } = this.state;
		this.setState({
			showOverlay: false,
			onHideOverlay: null,
		}, onHideOverlay);
	}

	onToggleModalAdvancedSearchHandler() {
		if (!this.isLoading()) {
			const { showModalAdvancedSearch } = this.state;
			this.setState({
				showModalAdvancedSearch: !showModalAdvancedSearch,
			});
		}
	}

	render() {
		const { currentTab, param, keyword, showOverlay, showModalAdvancedSearch } = this.state;
		const isLoading = this.isLoading();
		let info = this.props.transactionsInfo;
		let isTransferOrder = false;
		let placeholder = loc.orderSearchHelpText;
		switch (currentTab) {
		case 'transferIn':
			info = this.props.transferInOrderInfo;
			placeholder = loc.stockTransferOrderSearchHelpText;
			isTransferOrder = true;
			break;
		case 'transferOut':
			info = this.props.transferOutOrderInfo;
			placeholder = loc.stockTransferOrderSearchHelpText;
			isTransferOrder = true;
			break;
		}

		return (
			<Fragment>
				<div className="uk-flex uk-flex-column">
					<Top name="orderSearch" />

					<div className={ classnames('uk-flex', 'uk-padding-small', styles.searchBar) }>
						<div className="uk-flex uk-flex-1">
							<div className="uk-inline uk-width-expand">
								<div className={ styles.searchContainer }>
									<form onSubmit={ this.onSubmitHandler }>
										<div className={ styles.search }>
											<a onClick={ this.onSubmitHandler } className="uk-form-icon">
												<FontAwesomeIcon icon={ faSearch } className="uk-text-muted" />
											</a>
											<input
												type="text"
												value={ keyword }
												placeholder={ placeholder }
												className="uk-input uk-border-rounded"
												onChange={ this.onChangeKeywordHandler }
												disabled={ isLoading }
											/>
										</div>
										<div className={ styles.btnSearch }>
											<button
												type="submit"
												disabled={ isLoading }
												className="uk-button uk-button-small uk-button-secondary"
											>
												{ loc.search }
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
						<div className={ classnames( 'uk-padding-remove-vertical', 'uk-text-middle', styles.button) } onClick={ this.onToggleModalAdvancedSearchHandler }>
							<FontAwesomeIcon icon={ faSlidersH } className={ classnames('fa-2x', styles.icon) } />
						</div>
					</div>

					<LoadingOverlay active={ isLoading }>
						<div className="uk-padding-small uk-padding-remove-horizontal">
							<Tab
								tabs={ this.tabs }
								current={ currentTab }
								onChange={ this.onChangeTabHandler }
							/>
						</div>

						<div
							className={ classnames('uk-height-large', 'uk-overflow-auto', styles.list) }
							data-uk-height-viewport="offset-top: true; offset-bottom: 87px"
						>
							<ul className="uk-list uk-list-divider">
								{
									info.data && info.data.data && info.data.data.map((transaction, index) => (
										isTransferOrder ? (
											<TransferOrderListItem
												key={ `page-order-search-transfer-order-list-item-${index}` }
												index={ index }
												order={ transaction }
												type={ currentTab }
												onShowOverlay={ this.onShowOverlayHandler }
												onHideOverlay={ this.onHideOverlayHandler }
											/>
										) : (
											<TransactionListItem
												key={ `page-order-search-transaction-list-item-${index}` }
												index={ index }
												type={ currentTab }
												transaction={ transaction }
												onSelect={ this.onSelectHandler }
												onShowOverlay={ this.onShowOverlayHandler }
												onHideOverlay={ this.onHideOverlayHandler }
											/>
										)
									))
								}
							</ul>
						</div>

						<Pagination
							data={ info.data }
							onPageChange={ this.onChangePageHandler }
						/>
					</LoadingOverlay>
				</div>

				<ModalAdvancedSearch
					isOpen={ showModalAdvancedSearch }
					onToggle={ this.onToggleModalAdvancedSearchHandler }
					// showShopFilter={ true }
					param={ param }
					onChange={ this.onChangeParamHandler }
				/>

				<Overlay
					isOpen={ showOverlay }
					onToggle={ this.onHideOverlayHandler }
				/>
			</Fragment>
		);
	}
}

export default connect(
	(state) => ({
		transactionsInfo: state.transactionsInfo,
		transferInOrderInfo: state.transferInOrderInfo,
		transferOutOrderInfo: state.transferOutOrderInfo,
		salesOrderInfo: state.salesOrderInfo,
		voidOrderInfo: state.voidOrderInfo,
	}),
	(dispatch) => ({
		getTransactions: para => dispatch(getTransactions(para)),
		getTransferInOrder: para => dispatch(getTransferInOrder(para)),
		getTransferOutOrder: para => dispatch(getTransferOutOrder(para)),
	})
)(PageOrderSearch);