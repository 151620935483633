exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._2K74N {\n  background-color: #F6F4F0;\n  text-align: center;\n  font-size: 15px;\n  color: #A0A0A0;\n  line-height: 45px;\n}\n.Aiicv {\n  position: absolute;\n  top: 0;\n  right: 0;\n  padding: 10px 15px;\n  color: #BD9471;\n  cursor: pointer;\n}\n._1jWIS ._2K1Y0 {\n  margin-top: 5px;\n  padding-top: 10px;\n  height: 410px;\n  overflow: auto;\n}\n._1jWIS ._2K1Y0 ul {\n  margin: 0;\n}\n._1jWIS ._2QzNv {\n  margin-top: 20px;\n  text-align: center;\n}\n._1jWIS ._2QzNv button {\n  margin-left: 5px;\n  margin-right: 5px;\n}\n", ""]);

// Exports
exports.locals = {
	"desc": "_2K74N",
	"selectAll": "Aiicv",
	"container": "_1jWIS",
	"list": "_2K1Y0",
	"buttons": "_2QzNv"
};