import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import loc from 'Components/languages';
import DropdownMenu from 'Components/dropdown-menu';
import * as cons from 'Redux/constants';
import { postApproval, voidOrder } from 'Redux/actions';
import ModalApproval from './ModalApproval.jsx';
import styles from './TransferOrderListItem.less';

class TransferOrderListItem extends PureComponent {
	constructor() {
		super();
		this.state = {
			showDropdownMenu: false,
			showModalApproval: false,
		};
		this.setIsVerified = this.setIsVerified.bind(this);
		this.onClickVoidOrderHandler = this.onClickVoidOrderHandler.bind(this);
		this.onToggleDropdownMenuHandler = this.onToggleDropdownMenuHandler.bind(this);
		this.onToggleModalApprovalHandler = this.onToggleModalApprovalHandler.bind(this);
		this.onRequestApprovalHandler = this.onRequestApprovalHandler.bind(this);
	}

	componentDidUpdate(prevProps) {
		const { approvalType, approvalUserId } = this.state;
		if (approvalType && approvalUserId) {
			const approvalInfo = this.props.approvalInfo;
			const prevApprovalInfo = prevProps.approvalInfo;
			const voidOrderInfo = this.props.voidOrderInfo;
			const prevVoidOrderInfo = prevProps.voidOrderInfo;
			if (approvalInfo && approvalInfo !== prevApprovalInfo) {
				switch (approvalInfo.type) {
				case cons.POST_APPROVAL.SUCCESS:
					this.setIsVerified(approvalInfo.data.is_verfied);
					break;
				case cons.POST_APPROVAL.FAILURE:
					this.setIsVerified(false);
					break;
				}
			}

			if (voidOrderInfo && voidOrderInfo !== prevVoidOrderInfo) {
				let resetState = true;
				switch (voidOrderInfo.type) {
				case cons.VOID_ORDER.SUCCESS:
					UIkit.notification('The order is voided successfully.', { status: 'success' });
					break;
				case cons.VOID_ORDER.FAILURE:
					UIkit.notification('The order is NOT voided.', { status: 'danger' });
					break;
				default:
					resetState = false;
					break;
				}

				if (resetState) {
					this.setState({
						showModalApproval: false,
						approvalType: null,
						approvalUserId: null,
					});
				}
			}
		}
	}

	setIsVerified(isVerified) {
		if (!isVerified) {
			return;
		} else {
			this.setState({
				showModalApproval: false,
			});
		}

		const { order } = this.props;
		const { approvalType, approvalUserId } = this.state;
		let param = {
			payload: {}
		};

		switch (approvalType) {
		case 'voidOrder':
			param.payload = {
				transaction_number: order.transaction_number,
				is_approval_needed: true,
				is_approval_verified: isVerified,
				approval_user_code: approvalUserId,
			};
			this.props.voidOrder(param);
			break;
		}
	}

	onToggleDropdownMenuHandler() {
		const { showDropdownMenu } = this.state;
		const { onShowOverlay, onHideOverlay } = this.props;
		this.setState({
			showDropdownMenu: !showDropdownMenu,
		});
		if (showDropdownMenu) {
			onHideOverlay();
		} else {
			onShowOverlay(this.onToggleDropdownMenuHandler);
		}
	}

	onClickVoidOrderHandler(e) {
		e.preventDefault();
		this.setState({
			showModalApproval: true,
			approvalType: 'voidOrder',
		}, this.props.onHideOverlay);
	}

	onToggleModalApprovalHandler() {
		this.setState({
			showModalApproval: false,
			approvalType: null,
		});
	}

	onRequestApprovalHandler({ user_id, code }) {
		const param = {
			payload: {
				user_id,
				code,
			},
		};
		this.setState({
			approvalUserId: user_id,
		});
		this.props.postApproval(param);
	}

	render() {
		const { order, type } = this.props;
		const { showDropdownMenu, showModalApproval } = this.state;
		const createDate = order.created_date ? moment(order.created_date) : null;
		return (
			<Fragment>
				<li className={ classnames(styles.list) }>
					<div className="uk-card uk-card-body uk-card-small uk-position-relative">
						<div className="uk-flex uk-flex-between uk-text-small uk-text-muted uk-text-capitalize">
							<div className="uk-width-1-5 uk-text-primary uk-h6 uk-margin-remove-bottom">
								{ order.transaction_number }
							</div>
							<div className="uk-width-1-5">
								{ loc.createDate }: { createDate ? createDate.format('YYYY-MM-DD HH:mm:ss') : '' }
							</div>
							<div className="uk-width-1-5">
								{
									type === 'transferOut' ? (
										`${loc.destination}: ${order.wh_desc_to}`
									) : (
										`${loc.sourceDestination}: ${order.wh_desc_from}`
									)
								}
							</div>
							<div className="uk-width-1-5 uk-position-relative">
								{ loc.qty }: { order.item_count }
							</div>
							<div className="uk-width-1-5 uk-h6 uk-text-uppercase uk-margin-remove">
								{ loc[order.status] }
							</div>
						</div>
						{
							order.remark && (
								<div className="uk-flex uk-flex-between uk-text-small uk-text-muted">
									<div className="uk-width-1-1">
										<div className={ styles.remark }>{ order.remark }</div>
									</div>
								</div>
							)
						}
						<div className={ classnames('uk-position-top-right', styles.menu) }>
							<div className={ styles.button } onClick={ this.onToggleDropdownMenuHandler }>
								<FontAwesomeIcon icon={ faEllipsisV } className="fa-lg" />
							</div>
							<DropdownMenu
								width="200px"
								active={ showDropdownMenu }
								onToggle={ this.onToggleDropdownMenuHandler }
								className={ styles.dropdownMenu }
							>
								<li>
									<Link to={ `/app/stock-transfer/${type === 'transferOut' ? 'out' : 'in'}/${order.order_type}/${order.transaction_number}` }>
										{ loc.viewOrder }
									</Link>
								</li>
								{
									type === 'transferOut' && createDate.isSame(new Date(), 'day') && order.status !== 'cancel' && (
										<li>
											<a href="#" className="uk-text-danger" onClick={ this.onClickVoidOrderHandler }>{ loc.voidOrder }</a>
										</li>
									)
								}
							</DropdownMenu>
						</div>
					</div>
				</li>

				<ModalApproval
					isOpen={ showModalApproval }
					onToggle={ this.onToggleModalApprovalHandler }
					onSubmit={ this.onRequestApprovalHandler }
				/>
			</Fragment>
		);
	}
}

TransferOrderListItem.propTypes = {
	index: PropTypes.number.isRequired,
	order: PropTypes.object.isRequired,
	type: PropTypes.string.isRequired,
	onShowOverlay: PropTypes.func.isRequired,
	onHideOverlay: PropTypes.func.isRequired,
};

export default connect(
	(state) => ({
		approvalInfo: state.approvalInfo,
		voidOrderInfo: state.voidOrderInfo,
	}),
	(dispatch) => ({
		postApproval: (para) => dispatch(postApproval(para)),
		voidOrder: (para) => dispatch(voidOrder(para)),
	})
)(TransferOrderListItem);